.address-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-family: monospace;
}

.address-item {
  display: flex;
  align-items: center;
  padding-left: 5px; /* Add left padding */
}

.symbol {
  margin-right: 5px;
}

.address-link {
  color: black;
  text-decoration: underline; /* Keep address underlined */
  margin-right: 5px;
  font-size: 11px;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.copy-button:active {
  transform: scale(0.7);
}
