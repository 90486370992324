body {
  /* font-family: 'Source Code Pro', monospace; */
  font-family: 'Work Sans', sans-serif;
}

.App {
  /* text-align: center; */
  background-color: white;
  color: black;
}

.intro,
.info {
  padding: 8px;
  padding-top: 0px;
  padding-right: 40px;
}

header {
  font-family: 'Arial', sans-serif; /* or whichever font you prefer for the header */
  /* padding: 20px; */
  font-size: 1em;
}

hr {
  border: 0;
  height: 1px;
  background: black;
  margin: 20px 0;
}

.switch-container {
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.switch-container label {
  display: flex;
  align-items: center;
  font-size: 14px;
  /* font-weight: 500; */
  cursor: pointer;
  margin-left: 10px;
}

.switch-container label span {
  margin-right: 10px;
}

.react-switch {
  position: relative;
  transform: scale(0.6);
}

.tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  white-space: normal;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.react-switch:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.chart-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 500px;
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px;
  }
}

.information-section,
.harvest-data-section {
  margin-top: 20px;
  text-align: left;
}

.react-tabs {
  padding-bottom: 20px;
  margin-left: 10px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #ccc;
  border-radius: 5px 5px 0 0;
  color: #333;
  font-weight: bold;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  padding: 5px 0;
  /* box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.528); /* Slight dark shadow */
}

footer a {
  margin: 0 5px;
  color: #333;
  text-decoration: none;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
}

footer a i {
  margin-right: 8px;
}

.main-header {
  text-align: center;
  margin-bottom: 0; /* Remove bottom margin */
}

.sub-header {
  text-align: center;
  margin-top: 5px; /* Reduce space between header and sub-header */
}
