.gauge-votes {
  max-width: 100%;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.black-link {
  color: black;
  text-decoration: none;
}

.black-link:hover {
  text-decoration: underline;
}

.gauge-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.gauge-input {
  flex: 1;
  min-width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button,
.page-button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover,
.page-button:hover {
  background-color: #e0e0e0;
}

.loading {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
}

.table-container {
  overflow-x: auto;
}

.vote-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.vote-table th,
.vote-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.vote-table th {
  background-color: #f2f2f2;
}

.account-column,
.monospace {
  font-family: Consolas, monospace;
}

.amount-column,
.weight-column {
  text-align: right !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.page-button:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .vote-table {
    font-size: 12px;
  }

  .vote-table th,
  .vote-table td {
    padding: 4px;
  }

  .pagination {
    font-size: 14px;
  }
}
